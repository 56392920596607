import React, { useMemo } from "react";
import {MaterialReactTable, type MRT_ColumnDef}  from "material-react-table";
import { Link} from "@mui/material";
import { hover } from "@testing-library/user-event/dist/hover";
import "../index.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


const COLUMNS_CONFIGURATION = [
  {
    accessorKey: "ticker", //access nested data with dot notation
    header: "Ticker",
    enableClickToCopy: true,
    size: 50,
  },
  {
    accessorFn: (row: any) => Number(row.Last_Closing),
    id: 'Last_Closing',
    header: "Last_Closing",
    filterVariant: "range",
  },
  {
    accessorFn: (row: any) => Number(row.Performance),
    id: 'Performance',
    header: "Performance (%)",
    filterVariant: "range",
    filterFn: 'betweenInclusive',
  }, 
  {
    accessorKey: "rating_formations",
    header: "Rating Formations",
    filterVariant: "range",
  },
  {
    accessorKey: "rating_trend_50",
    header: "Rating Trend SMA50",
    filterVariant: "range",
  },
  {
    accessorKey: "rating_trend_200",
    header: "Rating Trend SMA200",
    filterVariant: "range",
    size: 50,
  }, 
  {
    accessorFn: (row: any) => Number(row.Perf_Rank),
    id: 'Perf_Rank',
    header: "Performance Rank",
    filterVariant: "range",
  },
  {
    accessorFn: (row: any) => Number(row.max),
    id: 'max',
    header: "Start to High (%)",
    filterVariant: "range",
  },
  {
    accessorFn: (row: any) => Number(row.min),
    id: 'min',
    header: "Start to Low (%)",
    filterVariant: "range",
  },

  //{
    //accessorKey: 'link',
    //header: "Link to Chart",
    //accessorKey: 'link',
    //header: "Link to Chart",
  //}
    //render: (data: any) => (
    //  <a href={`https://finviz.com/quote.ashx?t=${data.ticker}`} target="_blank" rel="noopener noreferrer">
    //    TEST
    //  </a>
    //),
];

/**
 * Das hier ist eine Component, der wird eine propery mitgegeben mit dem namen data,
 * data beinhaltet ein array mit allen Daten für die Material-React-Table Komponente
 *
 * Kann dann so aufgerufen werden von woanders (siehe App.js):
 * <MyChartScreener data={varialbeMitDemArrayDrin} />
 */
 export const MyChartScreener = ({ data }: { data: any }) => {
  return (
    
    <MaterialReactTable
      columns={COLUMNS_CONFIGURATION as any}
      data={data}
      enableColumnFilterModes
      enableColumnOrdering
      enablePinning
      enableRowSelection
      enableDensityToggle
      enableStickyHeader
      muiTableBodyRowProps={{
        sx: {
          height: "10px",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          p: "2px 16px",
        },
      }}
      initialState={{ showColumnFilters: true, density: "compact", pagination: {
        pageSize: 25,
        pageIndex: 0
      },
      sorting: [
        {
          id: 'rating_formations', //sort by age by default on page load
          desc: true,
        },
        {
          id: 'rating_trend_50', //then sort by lastName if age is the same
          desc: true,
        },
        {
          id: 'rating_trend_200', //then sort by lastName if age is the same
          desc: true,
        },
      ],
      }
    }
    />
  );
};