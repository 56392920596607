import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Disclaimer from "./pages/disclaimer";
import Error from "./pages/error";
import Home from "./pages/home";
import Impressum from "./pages/impressum";

export const router =createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <Error />,
    },
    {
        path: "/impressum",
        element: <Impressum />,
        errorElement: <Error />,
    },
    {
        path: "/disclaimer",
        element: <Disclaimer />,
        errorElement: <Error />,
    },
]);