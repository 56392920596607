// Impressum.tsx
import React from 'react';

const Impressum = () => {
  return (
    <div>
      <h1>Impressum</h1>
      <p>Information according to § 5 TMG:</p>
      <p>Chartalyst GmbH</p>
      <p>c/o Block Services</p>
      <p>Stuttgarter Str. 106</p>
      <p>70736 Fellbach</p>
      <p>Germany</p>

      <p>Represented by:</p>
      <p>Managing Director: Fabian Morbitzer</p>

      <p>Contact:</p>
      <p>Email: <a href="mailto:info@mychartscreener.com">info@mychartscreener.com</a></p>

      <p>Commercial Register:</p>
      <p>Court of registration: Local Court of Ulm</p>
      <p>Registration number: HRB 746885</p>

      <p>VAT ID:</p>
      <p>VAT identification number according to §27a Value Added Tax Act: DE123456789</p>

      <p>Responsible for content according to § 55 Abs. 2 RStV:</p>
      <p>Fabian Morbitzer</p>
      <p>Managing Director Chartalyst GmbH</p>
      <p>c/o Block Services</p>
      <p>Stuttgarter Str. 106</p>
      <p>70736 Fellbach</p>
      <p>Germany</p>

      <p><a href="/">Back to Main Site</a></p>
    </div>
  );
};

export default Impressum;