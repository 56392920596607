import React from "react";
import { FC } from "react";

interface LoadingTextProps {
  isLoading: boolean;
}

const LoadingText: FC<LoadingTextProps> = ({ isLoading }) => (
    <div>{isLoading ? <h2>Loading...</h2> : <span>Calculation done.</span>}</div>
);

export default LoadingText;