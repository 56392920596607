import React from "react";
import { useState } from "react";
import { MyChartScreener } from "./components/MyChartScreener";
import ErrorText from "./components/ErrorText";
import dataframe from "./result_dataframe.json";
import "./index.css";
import LoadingText from "./components/LoadingText";
import { LinkToChart } from "./components/LinkToChart";
import { Link, RouterProvider } from 'react-router-dom';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { router } from "./routes";


const BACKEND_URL = "https://api.my-chart-screener.de/data";
//const BACKEND_URL = "http://87.106.193.214:8000/data"
//const BACKEND_URL = "http://localhost:8000/data";

export async function getData({
  ticker,
  startdate,
  enddate,
}: {
  ticker: string;
  startdate: string;
  enddate: string;
}): Promise<any> {
  return fetch(BACKEND_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ticker,
      startdate,
      enddate,
    }),
  }).then((res) => {
    if (!res.ok) {
      // This will activate the closest `error.js` Error Boundary
      throw new Error("Failed to fetch paths");
    }
    return res.json();
  });
}

export const App = function () {
  const [ticker, setTicker] = useState("AAPL");
  const [startdate, setStartdate] = useState("2024-05-02");
  const [enddate, setEnddate] = useState("2024-05-15");
  const [data, setData] = useState(dataframe);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);


  const update = function () {
    if (!isUpdating) {
      setIsUpdating(true);
      setIsLoading(true);
      getData({ ticker, startdate, enddate })
        .then((result) => {
          setIsLoading(false);
          setIsUpdating(false);
          setIsError(false);
          console.log(result);
          setData(result);
        })
        .catch((err) => {setIsError(true);setIsLoading(false)});
      }
  };

  if (!data) {
    return (
      <div>
        Bitte warten, Fabian muss das noch kurz etwas ausrechnen und ist gleich
        für Sie da!
      </div>
    );
  }

  const onChangeTicker = (event: any) => setTicker(event.target.value);
  const onChangeStartDate = (event: any) => setStartdate(event.target.value);
  const onChangeEndDate = (event: any) => setEnddate(event.target.value);

  return (
    <div>
      <h1>My Chart Screener</h1>
      <h4>Find the Perfect Match to your Favorite Chart Pattern</h4>
      <h4>Enter Chart Formation:</h4>
      <div className="filtermenue">
        <span className="filter">
          <label>Ticker:</label>
          <input
            type="text"
            name="ticker"
            placeholder={ticker}
            onChange={onChangeTicker}
          />
        </span>
        <span className="filter">
          <label>Startdate:</label>
          <input
            type="date"
            id="start"
            name="startdate"
            defaultValue={startdate}
            onChange={onChangeStartDate}
          />
        </span>

        <span className="filter">
          <label>Enddate:</label>
          <input
            type="date"
            id="end"
            name="enddate"
            defaultValue={enddate}
            onChange={onChangeEndDate}
          />
        </span>

        <button className="filterbutton" onClick={update} disabled={isUpdating}>
          {isUpdating ? 'Updating...' : 'Update'}
        </button>
      </div>
      <ErrorText isError={isError} />
      <LoadingText isLoading={isLoading} />
      <div>Your Input: 
        <span className="yourInput">{data[data.length - 1].ticker.slice(0, -22)} </span>
        <span className="yourInput">{data[data.length - 1].ticker.slice(-21, -11)} </span>
        <span className="yourInput">{data[data.length - 1].ticker.slice(-10)} </span>
        <span className="greyText">(YYYY-MM-DD)</span>
      </div>
      <MyChartScreener data={data} />
      <footer>
        <h4>Chartalyst GmbH</h4>
        <p>c/o Block Services</p>
        <p>Stuttgarter Str. 106</p>
        <p>70736 Fellbach</p>
        <p>info (at) mychartscreener.com</p>

        <RouterProvider router={router}/>
           
        </footer>
    </div>
  );
};