import React from 'react';

const Home = () => {
  return (
    <div>
      <p><a href="/impressum">Impressum</a></p>
      <p><a href="/disclaimer">Disclaimer</a></p>
    </div>
  );
};

export default Home;