import React from 'react';

const Disclaimer = () => {
  return (
    <div>
      <h1>Disclaimer: Chartalyst GmbH (mychartscreener.com)</h1>

        <p>Last Updated: February 20, 2024</p>

        <h2>No Financial Advice</h2>
        <p>
            The information provided on mychartscreener.com (the "Website") is for general informational purposes only and is not intended as financial advice. The content on this Website, including charts, analyses, and commentary, is provided for educational and informational purposes only. It should not be considered as a recommendation, endorsement, or solicitation to buy or sell any financial instrument.
        </p>

        <h2>User Responsibility</h2>
        <p>
            Users of this Website are solely responsible for their own trading and investment decisions. It is important for users to conduct their own research and analysis and seek the advice of qualified financial professionals before making any investment decisions. Chartalyst GmbH ("the Company") does not guarantee the accuracy, completeness, or reliability of any information on the Website and shall not be held responsible for any trading or investment decisions made based on such information.
        </p>

        <h2>Risk Disclosure</h2>
        <p>
            Trading and investing in financial markets involve a high level of risk. Users should be aware of the risks associated with trading and carefully consider their investment objectives, financial situation, and risk tolerance before making any investment decisions. The Company shall not be liable for any financial losses, damages, or other consequences resulting from the use of information provided on the Website.
        </p>

        <h2>No Warranty</h2>
        <p>
            The Company makes no representations or warranties of any kind, expressed or implied, regarding the accuracy, reliability, or completeness of the information on the Website. The Company disclaims any liability for errors or omissions in the content provided on the Website.
        </p>

        <h2>Past Performance and Testimonials</h2>
        <p>
            Past performance is not indicative of future results. You acknowledge and agree that no promise or guarantee of success or profitability has been made between you and Chartalyst GmbH. Testimonials as presented may not be representative of all reasonably comparative users.
        </p>

        <h2>Changes to Disclaimer</h2>
        <p>
            Chartalyst GmbH reserves the right to modify or update this disclaimer at any time without prior notice. Users are encouraged to review this disclaimer regularly for any changes.
        </p>

        <p>
            By using mychartscreener.com, users acknowledge that they have read, understood, and agreed to the terms of this disclaimer.
        </p>

        <p>If you have any questions regarding this disclaimer, please contact us at <a href="mailto:info@mychartscreener.com">info@mychartscreener.com</a>.</p>

        <p><a href="/">Back to Main Site</a></p>
    </div>
  );
};

export default Disclaimer;