import React from "react";
import { FC } from "react";

interface ErrorTextProps {
  isError: boolean;
}

const ErrorText: FC<ErrorTextProps> = ({ isError }) => (
    <div>{isError ? <h2>Error: Please check entered Ticker Symbol.</h2> : <span>Status: OK</span>}</div>
);

export default ErrorText;